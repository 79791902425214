<template>
  <div>
    <v-container fluid>

      <div class="main">
        <v-row>
          <v-col cols="12">

            <div class="d-flex justify-space-between mb-3">
              <v-spacer/>
              <v-btn color="gifty"
                     dark
                     depressed
                     @click="addUser">
                <v-icon left>mdi-plus</v-icon>
                Ajouter
              </v-btn>
            </div>

            <v-card class="rounded-lg shadow">

              <v-overlay :value="overlay"
                         absolute
                         color="white"
                         opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                Recouvreurs
                <v-spacer/>
                <v-btn depressed
                       exact
                       fab
                       small
                       to="/recoveries">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>

                <v-card-text class="pa-0">

                  <v-simple-table v-if="recuperators.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Active</th>
                        <th v-if="can('admin')">Fournisseur</th>
                        <th>Nom complet</th>
                        <th>Nom d'utilisateur</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Services</th>
                        <th>POS</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,i) in recuperators" :key="i">
                        <td>
                          <v-switch v-model="active"
                                    class="mt-0 pt-0"
                                    color="success"
                                    hide-details readonly/>
                        </td>

                        <td v-if="can('admin')" class="font-weight-medium primary--text text-no-wrap">
                          <v-icon color="primary" size="10">mdi-circle</v-icon>
                          {{ item.supplier ? item.supplier.name : '-' }}
                        </td>


                        <td>{{ item.name }}</td>

                        <td class="font-weight-medium gifty--text">
                          {{ item.username }}
                        </td>


                        <td>{{ item.email }}</td>
                        <td>{{ item.phone }}</td>
                        <td>
                          <v-chip color="primary" dark small
                                  @click="$refs.userOrPosDialog.open(item.recuperator_services,'Services')">
                            <v-icon left small>mdi-format-list-checks</v-icon>
                            {{ item.recuperator_services.length }}
                          </v-chip>
                        </td>
                        <td>
                          <v-chip color="primary" dark small
                                  @click="$refs.userOrPosDialog.open(item.salepoints,'POS')">
                            <v-icon left small>mdi-format-list-checks</v-icon>
                            {{ item.salepoints.length }}
                          </v-chip>
                        </td>
                        <td>
                          <v-btn icon @click="update(item)">
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getRecuperators"/>
                  </div>

                </v-card-text>

              </div>

            </v-card>
          </v-col>

        </v-row>
      </div>

      <FormDialog ref="formDialog" @refresh="getRecuperators"/>
      <UserOrPosDialog ref="userOrPosDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults.vue";
import FormDialog from "./FormDialog.vue";
import UserOrPosDialog from "./UserOrPosDialog.vue";

export default {
  components: {FormDialog, UserOrPosDialog, NoResults},
  data() {
    return {
      id: null,
      active: true,

      isLoading: false,
      overlay: false,
      recuperators: []
    };
  },
  methods: {
    getRecuperators() {
      this.isLoading = true
      HTTP.get('/v1/recoveries/recuperators').then(res => {
        this.isLoading = false
        this.recuperators = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    addUser() {
      this.$refs.formDialog.open()
    },
    update(item) {
      this.$refs.formDialog.open(item)
    }
  },
  created() {
    this.getRecuperators()
  }
};
</script>

<style scoped></style>
